export function calculateRiskLevel(percentage) {
  if (percentage < 40) {
    return 'low';
  }

  if (percentage > 75) {
    return 'high';
  }

  return 'medium';
}

export function calculatePercentage(partialValue, totalValue) {
  const percentage = (100 * partialValue) / totalValue;

  if (percentage <= 100) return percentage;
  return 100 - (percentage % 10);
}
